<template>
  <ul v-if="!isinit" class="badge-items">
    <li class="badge-item" v-for="(item, idx) in allBadges" :key="idx">
      <div class="badge-img">
        <i v-if="item && item.isNew" class="icon-newblk is-active">new</i>
        <SSLBadge
            v-if="item.division === 'ssl'"
            :badgeTyCdDcd="item.badgeTyCdDcd"
            :is-active="item.isActive"
        />
        <HrdCloudBadge
            v-else
            :badge-ty-cd-dcd="item.badgeTyCdDcd"
            :is-active="item.isActive"
        />
      </div>
      <div class="badge-desc">
        <p class="name">{{ item.badgeTyNm }}</p>
      </div>
    </li>
  </ul>
</template>

<script>
import { onMounted, ref} from 'vue';
import HrdCloudBadge from '@/components/history/hrdcloud/HrdCloudBadge.vue';
import SSLBadge from '@/components/ssl/badge/SSLBadge.vue';
// import {hrdBadges} from '@/assets/js/modules/hrd/hrd-badge';
// import {sslBadges} from '@/assets/js/modules/ssl/ssl-badge';
import {sortByActiveAndNew} from '@/assets/js/modules/common/common';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";

export default {
  name: 'RecentBadges',
  components: {SSLBadge, HrdCloudBadge},
  props: {
    badgeTyCdDcds: Array,
    year: {
      required: false,
      default() {
        return new Date().getFullYear().toString();
      },
    }
  }
  , setup: function (props) {
    const currentDate = new Date();
    const sslHrdBadgesTmp = ref([]);
    const hrdBadges = ref([]);
    const sslBadges = ref([]);

    const currentYear = currentDate.getFullYear().toString();
    const currentTimestamp = currentDate.getTime();
    const hrdcloudBadges = ref([]);
    const sslGetBadges = ref([]);
    const allBadges = ref([]);
    const badgeNms = ref([]);
    const isinit = ref(true);

    // const hrdBadgescol = new Date().getFullYear() - 2022;

    const staticHrd = ref([]);

    const staticSsl = ref([]);

    const mergeArrayByKey = (arr1, arr2, key) => {
      // const map2 = new Map(arr2.map(item =>[item[key],item]));
      //
      // return arr1.map(item =>{
      //   const item2 = map2.get(item[key]);
      //   return item2 ? {...item, ...item2}: {...item};
      // });
      const combinedArray = [...arr1, ...arr2];
      const mergedObjects = combinedArray.reduce((acc, item) => {
        acc[item[key]] = acc[item[key]] ? {...acc[item[key]], ...item} :{...item};
        return acc;
      }, {});
      return Object.values(mergedObjects);
    }

    const sortAndSliceArray = (arr, sortByKey,numberOfItems) =>{
      const sortedArray = arr.sort((a,b)=>  (b[sortByKey] ? b[sortByKey]: 0 ) - (a[sortByKey]? a[sortByKey] :0) );
      return sortedArray.slice(0,numberOfItems);
    }
    // 배지가 없을때 고정으로 보여줄 배지 세팅
    const staticBadgeList = async () => {

      if (props.badgeTyCdDcds) {

        hrdcloudBadges.value = sortAndSliceArray(mergeArrayByKey(hrdcloudBadges.value, staticHrd.value, "badgeTyCdDcd"),"getDt",3);

        sslGetBadges.value = sortAndSliceArray(mergeArrayByKey(sslGetBadges.value, staticSsl.value, "badgeTyCdDcd"),"getDt",3);
      }

    };


    const setBadges = async () => {
      hrdcloudBadges.value = props.badgeTyCdDcds.filter(x => x.division === 'hrdcloud');
      sslGetBadges.value = props.badgeTyCdDcds.filter(x => x.division === 'ssl');

      staticHrd.value = sslHrdBadgesTmp.value.filter(x => x.badgeTyCdDcd === '1001' || x.badgeTyCdDcd === '1002' || x.badgeTyCdDcd === '1035');
      staticSsl.value = sslHrdBadgesTmp.value.filter(x => x.badgeTyCdDcd === '2015022' || x.badgeTyCdDcd === '2015005' || x.badgeTyCdDcd === '2015017');

      await staticBadgeList();

      hrdBadges.value = sslHrdBadgesTmp.value.filter(x => x.badgeKindCdDcd === "2065002");
      sslBadges.value = sslHrdBadgesTmp.value.filter(x => x.badgeKindCdDcd === "2065001");


      let myHrdBadges = [];
      hrdBadges.value.map(x => {
        const hrd = hrdcloudBadges.value.find(y => x.badgeTyCdDcd === y.badgeTyCdDcd);

        if(hrd){
          myHrdBadges.push({

            isActive: (hrd && hrd.getDt) > 0,
            isNew: (hrd && hrd.getDt + (1000 * 60 * 60 * 24 * 7)) >= currentTimestamp,
            division: 'hrdcloud',
            ...x
          });
        }

      });



      let mySslBadges = [];
      sslBadges.value.map(x => {
        const ssl = sslGetBadges.value.find(y => x.badgeTyCdDcd === y.badgeTyCdDcd);
        if(ssl) {
          mySslBadges.push({
            isActive: (ssl && ssl.getDt) > 0,
            isNew: (ssl && ssl.getDt + (1000 * 60 * 60 * 24 * 7)) >= currentTimestamp,
            division: 'ssl',
            badgeTyCdDcd: x.badgeTyCdDcd,
            badgeTyNm: x.badgeTyNm instanceof Object ? x.badgeTyNm[currentYear] : x.badgeTyNm,
            cdDesc: x.cdDesc instanceof Object ? x.cdDesc[currentYear] : x.cdDesc,
          });
        }
      });

      sortByActiveAndNew(myHrdBadges);
      sortByActiveAndNew(mySslBadges);

      for (let i = 0; i < 3; i++) {
        allBadges.value.push(myHrdBadges[i]);
        allBadges.value.push(mySslBadges[i]);
      }


    };
    const init = async () => {

      // console.log(allBadges)
      await getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear}, sslHrdBadgesTmp, null, setBadges);
      // sslBadges.value =.sort((a, b) => (b.badgeKindCdDcd - a.badgeKindCdDcd));
      // hrdBadges.value = sslHrdBadgesTmp.value.filter(x => x.badgeKindCdDcd === "2065002").sort((a, b) => (b.badgeKindCdDcd - a.badgeKindCdDcd));

    }


    onMounted(async () => {
      await init();
      isinit.value = false;

    });


    return {
      isinit,
      allBadges,
      badgeNms
    }
  }
};
</script>
ㄲ